/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Orders container;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  12-09-2022
*/
/*import packages*/
import { useState, useEffect, useRef, Fragment } from 'react';
import devconfig from '../../config/config';
import { EmptyNote } from '../../components/note';
import { DataTable, FundsTab } from '../../components/dataFormating';
import { Loader } from '../../components/loader';
import APIPost from '../../services/postApi';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectBox from '../../components/dropdown/selectBox';
import APIGet from '../../services/getApi';
import { PrimaryLink } from '../../components/cta';
import { Modal } from 'bootstrap';
import { DeleteOrderModal } from '../../components/modal';

const STATUS_OPTIONS = [
    {
        label: "Pending", value: "PENDING"
    },
    {
        label: "Order Placed", value: "ORDER_PLACED"
    },
    {
        label: "Order Failed", value: "ORDER_FAILED"
    },
    {
        label: "Order Completed", value: "ORDER_COMPLETED"
    }
]


const PendingOrders = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialRef = useRef(false);
    const [orderData, setOrderData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [loader, setLoader] = useState(true);

    const [totalRecords, setTotalRecords] = useState(null);

    const [tabIndex, setTabindex] = useState(null);
    const [orderId, setOrderId] = useState(null);


    useEffect(() => {
        if (tabIndex !== null) {
            if (tabIndex === 1) {
                _filterData();
            } else {
                _basketFilterData();
            }
        }
    }, [tabIndex]);

    useEffect(() => {
        if (orderId !== null) {
            const modal = new Modal(document.getElementById("delete-order"), {});
            modal.show();
        }
    }, [orderId])

    useEffect(() => {
        setPageNum(1);
        setPerPage(10);
        if (location.state !== null) {
            setTabindex(location.state.index);
        } else {
            setTabindex(1);
        }
    }, [location.state]);

    useEffect(() => {
        if (selectedOrderStatus !== null && selectedCustomer !== null && tabIndex !== null) {
            if (initialRef.current == false) {
                if (tabIndex === 1) {
                    getOrderList();
                } else {
                    basketOrderList();
                }
                initialRef.current = true
            }
        }
        //eslint-disable-next-line
    }, [selectedOrderStatus, perPage, pageNum, selectedCustomer, tabIndex]);



    const _handleTab = (temp) => {
        if (temp !== tabIndex) {
            setLoader(true);
            navigate("/pending-orders", {
                state: {
                    index: temp
                }
            })
            setTabindex(temp);
            setPageNum(1);
            setPerPage(10);
            initialRef.current = false
        }
    }

    const _handlePageChange = (page_num) => {
        setPageNum(page_num);
        initialRef.current = false;
    };

    const _handlePerPage = (per_page) => {
        setPerPage(per_page);
        initialRef.current = false;
    };

    const _getStatus = (status) => {
        const status_label = STATUS_OPTIONS.find((item) => item.value === status);
        if (status_label) {
            return status_label.label
        }
    }

    /*--------- order list -----------*/
    const getOrderList = (query) => {
        let url = devconfig.admin + '/order/reorder-list';

        let data = JSON.stringify({
            "customer_id": selectedCustomer.value,
            "status": selectedOrderStatus.value,
            page_num: pageNum,
            page_size: perPage,

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.data);
                setTotalRecords(response.data.total_records)
            } else {
                setOrderData([]);
            }
            setLoader(false);
        });
    }


    /*--------- cart pending order list -----------*/
    const basketOrderList = (page_num,page_size) => {
        let url = devconfig.admin + "/basket-order/list";

        let data = JSON.stringify({
            "customer_id": selectedCustomer.value,
            "status": selectedOrderStatus.value,
            page_num: page_num? page_num :pageNum,
            page_size: page_size? page_size :perPage,

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.data);
                setTotalRecords(response.data.total_records)
            } else {
                setOrderData([]);
            }
            setLoader(false);
        });
    }

    /*---- filter data api --*/
    const _filterData = () => {
        let status_options_temp = [];

        status_options_temp.push({
            label: "All",
            value: null
        })


        let customers_options_temp = [];

        customers_options_temp.push({
            label: "All",
            value: null
        })

        let url = devconfig.admin + '/order/re-order-filter';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                response.data.status.map((item) => {
                    status_options_temp.push({ value: item.label, label: item.label });
                    return true;
                });
                setOrderStatusOptions(status_options_temp);
                setSelectedOrderStatus(status_options_temp[0]);

                response.data.customer_ids.map((item) => {
                    customers_options_temp.push({ value: item.id, label: item.name });
                    return true;
                });
                setCustomerOptions(customers_options_temp);
                setSelectedCustomer(customers_options_temp[0]);
                initialRef.current = false
            }
        });
    }

    /*---- filter data api --*/
    const _basketFilterData = () => {
        let status_options_temp = [];

        status_options_temp.push({
            label: "All",
            value: null
        })


        let customers_options_temp = [];

        customers_options_temp.push({
            label: "All",
            value: null
        })

        let url = devconfig.admin + "/basket-order/filter";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                response.data.status.map((item) => {
                    status_options_temp.push({ value: item.label, label: item.label });
                    return true;
                });
                setOrderStatusOptions(status_options_temp);
                setSelectedOrderStatus(status_options_temp[0]);

                response.data.customer_ids.map((item) => {
                    customers_options_temp.push({ value: item.id, label: item.name });
                    return true;
                });
                setCustomerOptions(customers_options_temp);
                setSelectedCustomer(customers_options_temp[0]);
                initialRef.current = false
            }
        });
    }

    const ORDER_COLUMNS = [
        {
            name: 'MIRA ID',
            selector: (row) => row.mira_customer_id ? row.mira_customer_id : '--',
            sortable: true,
            left: true,
            width: "150px",
            defaultSortAsc: false,
        },
        {
            name: 'Created on',
            selector: row => row.created,
            sortable: false,
            left: true,
            minWidth: '200px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
            cell: row => <div className="col-lg-12" >
                {row.created}
            </div>
        },
        {
            name: 'Name',
            selector: row => row.customer_name,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <>
                {
                    row.customer_name ?
                        <div className='e-link' onClick={() => {
                            navigate(`/user-detail?id=${row.customer_id}`, {
                                state: {
                                    tab: 2
                                }
                            })
                        }}>{row.customer_name}</div>
                        :
                        "--"
                }
            </>
        },
        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                ₹{
                    row.amount ?
                        (row.amount.toFixed(2)).toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
        {
            name: 'ISIN',
            selector: row => row.isin,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {row.isin}
            </div>

        },
        {
            name: 'Units',
            selector: row => row.units ? row.units : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {row.units ? row.units : "-"}
            </div>

        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            left: true,
            width: "200px",

            // cell: row => <div className="">
            //   <Badge type="success" text="success"/>
            // </div>

            cell: (row, key) =>
                <div className={
                    row.status === "ORDER_COMPLETED" ? `e-status-badge w-250px e-status-badge-success` :
                        row.status === "ORDER_FAILED" ? `e-status-badge w-250px e-status-badge-fail` :
                            row.status === "PENDING" ? `e-status-badge w-250px e-status-badge-pending` :
                                row.status === "ORDER_PLACED" ? `e-status-badge w-250px e-status-badge-pending` :
                                    'e-status-badge w-250px'
                }>
                    {_getStatus(row.status)}
                </div>

        },
        {
            name: 'Goal name',
            selector: row => row.goal_name,
            sortable: true,
            left: true,
            minWidth: '180px',
            cell: (row) => <div className="col-lg-12">
                {row.goal_name}
            </div>
        },
        {
            name: 'Type',
            selector: row => row.order_type,
            sortable: true,
            left: true,
            width: "180px",
            cell: (row) => <div className="col-lg-12">
                {row.order_type}
            </div>
        },
    ]

    const CART_ORDER_COLUMNS = [
        {
            name: 'MIRA ID',
            selector: (row) => row.customer_id ? row.customer_id : '--',
            sortable: true,
            left: true,
            width: "150px",
            defaultSortAsc: false,
        },
        {
            name: 'Created on',
            selector: row => row.created,
            sortable: false,
            left: true,
            minWidth: '200px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
            cell: row => <div className="col-lg-12" >
                {row.created}
            </div>
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <>
                {
                    row.name ?
                        <div className='e-link' onClick={() => {
                            navigate(`/user-detail?id=${row.user_id}`, {
                                state: {
                                    tab: 2
                                }
                            })
                        }}>{row.name}</div>
                        :
                        "--"
                }
            </>
        },
        {
            name: 'Amount',
            selector: row => row.type === "SIP" ? row.sip_amount : row.lumpsum_amount,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                ₹{
                    row.type === "SIP" ?
                        row.sip_amount && row.sip_amount.toLocaleString('en-IN')
                        :
                        row.lumpsum_amount && row.lumpsum_amount.toLocaleString('en-IN')
                }
            </div>

        },
        {
            name: 'ISIN',
            selector: row => row.isin,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {row.isin ? row.isin : "--"}
            </div>

        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            left: true,
            minWidth: "170px",

            // cell: row => <div className="">
            //   <Badge type="success" text="success"/>
            // </div>

            cell: (row, key) => <div className={
                row.status === "ORDER_COMPLETED" ? `e-status-badge w-250px e-status-badge-success` :
                    row.status === "ORDER_FAILED" ? `e-status-badge w-250px e-status-badge-fail` :
                        row.status === "PENDING" ? `e-status-badge w-250px e-status-badge-pending` :
                            row.status === "ORDER_PLACED" ? `e-status-badge w-250px e-status-badge-pending` :
                                'e-status-badge w-250px'
            }>
                {_getStatus(row.status)}
            </div>
        },
        {
            name: 'Basket/Fund',
            selector: row => row.goal_name,
            sortable: true,
            left: true,
            minWidth: '180px',
            cell: (row) => <div className="col-lg-12">
                {row.goal_name}
            </div>
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            left: true,
            width: "180px",
            cell: (row) => <div className="col-lg-12">
                {row.type}
            </div>
        },
        {
            name: '',
            cell: row => row.status === "PENDING" && <PrimaryLink name="Delete" className="e-delete-btn"
                handleClick={() => setOrderId(row.id)} />
        }
    ]
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0 py-1">
                        {
                            loader ?
                                <Loader />
                                :
                                <div className="row pt-2">
                                    <div className="col-lg-12">
                                        <FundsTab data={["Orders", "Cart orders"]}
                                            index={tabIndex}
                                            setTab={(temp) => {
                                                _handleTab(temp)
                                            }} />
                                    </div>
                                    <div className="e-filter-wraper e-sort mt-1">
                                        <div className="row align-items-end">
                                            <div className="col-lg-2 pe-1 col-3 ">
                                                <label className="mb-1">Order status</label>
                                                <SelectBox options={orderStatusOptions}
                                                    value={selectedOrderStatus}
                                                    placeHolder="Select order status"
                                                    isSearchable={false}
                                                    selectchange={(value) => {
                                                        setSelectedOrderStatus(value);
                                                        setPageNum(1);
                                                        setPerPage(10);
                                                        initialRef.current = false;
                                                    }}
                                                />
                                            </div>

                                            <div className="col-lg-2 px-1 col-3 ">
                                                <label className="mb-1">Customer</label>
                                                <SelectBox options={customerOptions}
                                                    value={selectedCustomer}
                                                    placeHolder="Select customer"
                                                    isSearchable={false}
                                                    selectchange={(value) => {
                                                        setSelectedCustomer(value);
                                                        setPageNum(1);
                                                        setPerPage(10);
                                                        initialRef.current = false;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {/*--------------- Order table -----------*/}
                        {
                            (!orderData.length) ?
                                <EmptyNote note="There are no orders to show" />
                                :
                                <div className="col-lg-12 px-0">
                                    <DataTable
                                        pagination={true}
                                        columns={tabIndex === 1 ? ORDER_COLUMNS : CART_ORDER_COLUMNS}
                                        data={orderData}
                                        perPage={perPage}
                                        paginationServer={true}
                                        selectableRows={false}
                                        paginationTotalRows={totalRecords}
                                        onChangeRowsPerPage={_handlePerPage}
                                        onChangePage={_handlePageChange}
                                        paginationDefaultPage={pageNum}
                                        selectRows={() => { }}
                                        rowClick={(row) => navigate(`/user-detail?id=${tabIndex === 1 ? row.customer_id : row.user_id}`, {
                                            state: {
                                                tab: 2
                                            }
                                        })} />
                                </div>
                        }
                    </div>
                </div>
            </div>
            {/* confirmation modal for deleting order */}
            {
                orderId !== null && <DeleteOrderModal
                    title={`Are you sure you want to delete this order?`}
                    buttonName={"Delete Order"}
                    id={orderId}
                    closeModal={() => {
                        setOrderId(null)
                    }}
                    updateStatus={() => {
                        setPageNum(1);
                        setPerPage(10);
                        basketOrderList(1,10);
                        setOrderId(null)
                    }} />
            }
        </Fragment>
    )
}

export default PendingOrders