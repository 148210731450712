/*
 *   File : delete-order.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Delete Modal
 *   Integrations : 09-02-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { Toast } from '../note/index';

/*import styles*/
import styles from '../../styles/components/confirmation.module.scss';

/*import services*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/* import assets */
// import Delete from '../../assets/images/modal/delete.svg'

export default function DeleteOrderModal(props) {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);

    useEffect(() => {
        var myModalEl = document.getElementById("delete-order")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {

            $(".fade").removeClass("modal-backdrop");
            $("body").removeAttr("style");
            props.closeModal();
        })
        //eslint-disable-next-line
    }, [])

    /* --- close delete modal on click ---*/
    const closeModal = () => {
        $(".btn-close").trigger("click");
        $("#delete-order").trigger('click');
        setLoader(false)
    }

    /*----- Delete target maturity fund -----------*/
    const _deleteOrder = () => {
        setLoader(true)
        const url = devconfig.admin + "/basket-order/cancel";
        const data = JSON.stringify({
            "basket_id": props.id
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                closeModal();
                props.updateStatus();
            } else {
                setLoader(false);
            }
        });
    }
    return (
        <div className={`${styles.e_confirmation} modal fade`} id={"delete-order"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>{`${props?.title}`}</h6>
                        <button type="button"
                            className="btn-close"
                            id="close-btn"
                            data-bs-dismiss="modal"
                            aria-label="Close" >
                        </button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            {/* <div className='col-lg-12 text-center'>
                                <img src={Delete} alt="" />
                            </div> */}
                            <div className="col-lg-12 text-center">
                                <p className={props.className}
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }}></p>
                            </div>
                        </div>
                        <div className="row pt-4 pb-2">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">

                                    <PrimaryBtn name={props.buttonName} className={
                                        loader ?
                                            `${styles.e_submit} e-modal-submit e-btn-loader me-3`
                                            :
                                            `${styles.e_submit} e-modal-submit me-3`
                                    }
                                        handleClick={() => {
                                            _deleteOrder()
                                        }} />
                                    <SecondaryBtn name="Cancel" className={styles.e_cancel} cancel="modal" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* error toast */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1"
                    />
                    :
                    null
            }
            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2"
                    />
                    :
                    null
            }
        </div>
    );
}
