import $ from 'jquery';

export default async function APIPost(url, data) {
  let token = localStorage.getItem('access_token')
  return await $.ajax({
    type: "POST",
    url: url,
    timeout: 60000,
    dataType: 'json',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    data: data
  })
    .then(function (response) { return response })
    .catch(function (response) {
      // console.log(response.responseJSON);
      if (response.responseJSON.status_code === 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location = "/?session=true";
      }
      else {
        return response.responseJSON
      }

    })
}
