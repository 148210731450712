/*
 *   File : sip-dates.js
 *   Author : https://evoqins.com
 *   Description : SIP date modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";

// Component
import { PrimaryBtn } from "../cta";
import style from "../../styles/components/sip-dates.module.scss"

const NORMALIZED_SIP_DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

const SIPDateModal = React.memo((props) => {

    const [selectedDate, setSelectedDate] = useState(props.selectedSIPDate);
    const [sipDateString, setSipDateString] = useState(props.selectedSIPDate);


    useEffect(() => {
        var my_modal = document.getElementById("sip-date");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.handleCloseDateModal();
        });
        //eslint-disable-next-line
    }, [props.handleCloseDateModal]);

    useEffect(() => {

        setSelectedDate(props.selectedSIPDate);

        let selected_date = props.selectedSIPDate;
        _selectDate(selected_date, props.selectedSIPDate)
        //eslint-disable-next-line
    }, [props.selectedSIPDate]);

    const _handleOnSelect = (date) => {
        setSelectedDate(date);
        _selectDate(date, date)
    };

    const _formatSip = (selected_date) => {
        switch (selected_date) {
            case 1: return "1st";
            case 2: return "2nd";
            case 3: return "3rd";
            case 21: return "21st";
            case 22: return "22nd";
            case 23: return "23rd";
            default: return selected_date + "th";
        };
    }

    const _selectDate = (selected_date, value) => {
        const date_string = _formatSip(selected_date);
        setSipDateString(date_string);
    }

    const _handleConfirmSIPDate = () => {
        if (selectedDate !== "") {
            props.handleSelect(parseFloat(selectedDate), sipDateString);
            props.handleCloseDateModal();
        };
    };

    const _handleCloseModal = () => {
        document.getElementById("close-sip").dispatchEvent(new Event("click"));

    };
    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="sip-date"
            tabIndex="-1"
            aria-labelledby="sip-date"
            aria-hidden="true"
            data-bs-backdrop={props.backdrop}
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ${style.e_content}`}>

                    <div className="px-sm-3 px-2">
                        <div className="d-flex align-items-center justify-content-between py-3">
                            <h6 className='mb-0'>
                                Select {props.title} date
                            </h6>
                            <button id="close-sip"
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={_handleCloseModal} >
                            </button>

                        </div>
                    </div>
                    <p className="mt-3 px-sm-3 px-2 text-center">{sipDateString} of every month</p>

                    <div className="px-sm-3 px-2 d-flex gap-2 flex-wrap text-center">
                        {NORMALIZED_SIP_DATES.map((date, key) => (
                            <div className={` d-flex align-items-center justify-content-center ${style.e_sip_date} ${selectedDate === date ? style.e_active_date : "e-sip-date color-eerie-black"} ${props.apiDates.length !== 0 && !props.apiDates.includes(date.toString()) ? "disabled opacity-5" : ""}`}
                                key={key}
                                onClick={() => _handleOnSelect(date)} >
                                {date}
                            </div>
                        ))}

                    </div>
                    <div className='mt-4 col-6 mx-auto'>
                        <PrimaryBtn name="Confirm"
                            cancel="modal"
                            className="padding-10px-tb w-100"
                            handleClick={_handleConfirmSIPDate} />
                    </div>

                </div>
            </div>
        </div>

    )
})

export default SIPDateModal;

SIPDateModal.defaultProps = {
    apiDates: [],
    backdrop: true,
    title: "SIP"
}