/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Orders container;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  12-09-2022
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

/*import components*/
import { DataTable, FundsTab } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn, TextBtn, PrimaryBtn } from '../../components/cta/index';
import { OrderDetail, SummaryModal } from '../../components/modal/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';
/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Tooltip from '../../components/badge/tooltip';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomMultiSelect from '../../components/dropdown/multi-select';


const Index = (props) => {

	const wrapperRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();

	const [tabIndex, setTabindex] = useState(1);
	const [search, setSearch] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [filterDown, setFilterDown] = useState(true);
	const [dates, setDates] = useState({
		start: null,
		end: null
	});
	const [cartDates, setCartDates] = useState({
		start: null,
		end: null
	});
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(true);
	const [error, setError] = useState(null);
	const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
	const [selectedPayStatus, setSelectedPayStatus] = useState([]);
	const [selectedGoal, setSelectedGoal] = useState([]);

	const [cartSelectedOrderStatus, setCartSelectedOrderStatus] = useState([]);
	const [cartSelectedPayStatus, setCartSelectedPayStatus] = useState([]);
	const [cartSelectedGoal, setCartSelectedGoal] = useState([]);
	const [orderStatusOptions, setOrderStatusOptions] = useState([]);
	const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
	const [goalOptions, setGoalOptions] = useState([]);
	const [cartGoalOptions, setCartGoalOptions] = useState([]);
	const [check, setCheck] = useState([])
	const [show, setShow] = useState(null);
	const [orderData, setOrderData] = useState([]);
	const [summaryData, setSummaryData] = useState({});
	const [totalRecords, setTotalRecords] = useState(null);
	const [totalValue, setTotalValue] = useState(0);
	const [details, setDetails] = useState({});
	const [success, setSuccess] = useState(null);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [type, setType] = useState(null);
	const [pageNum, setPageNum] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [showSummary, setShowSummary] = useState(false);


	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};

	}, []);



	useEffect(() => {
		filterData();
		basketfilterData();
	}, [])

	// useEffect(() => {
	// 	if (location.state !== null) {
	// 		filterData(location.state.index);
	// 	}
	// }, [location.state])

	useEffect(() => {
		setPageNum(1);
		setPerPage(10);
		if (location.state !== null) {
			setTabindex(location.state.index);
		} else {
			setTabindex(1);
		}
	}, [location.state]);

	useEffect(() => {
		if (showSummary === true) {
			const modal = new Modal(document.getElementById("summary"));
			modal.show();
		}
	}, [showSummary]);

	useEffect(() => {
		if (dates !== null && selectedPayStatus !== null && selectedOrderStatus !== null && type !== null && selectedGoal !== null && tabIndex === 1) {
			getOrderList({
				value: JSON.parse(sessionStorage.getItem("order_search")),
				page: pageNum,
				per_page: perPage
			});
		}
		//eslint-disable-next-line
	}, [dates, selectedPayStatus, selectedOrderStatus, type, selectedGoal, tabIndex, JSON.parse(sessionStorage.getItem("order_search"))]);

	useEffect(() => {
		if (cartDates !== null && cartSelectedPayStatus !== null && cartSelectedOrderStatus !== null && cartSelectedGoal !== null && tabIndex === 2) {
			getCartOrderList({
				value: JSON.parse(sessionStorage.getItem("cart_order_search")),
				page: pageNum,
				per_page: perPage
			});
		}
		//eslint-disable-next-line
	}, [cartDates, cartSelectedPayStatus, cartSelectedOrderStatus, cartSelectedGoal, tabIndex, JSON.parse(sessionStorage.getItem("cart_order_search"))]);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 3000)
		}
	}, [success])

	const typeOptions = [
		{ value: null, label: 'All' },
		{ value: 1, label: 'Lumpsum' },
		{ value: 2, label: 'SIP' },
		{ value: 3, label: "Withdraw" },
		{ value: 4, label: "Switch" }
	]

	/*---- filter data api --*/
	const filterData = (index) => {
		let payment_options_temp = [];
		let status_options_temp = [];
		let goal_temp = [];

		let url = devconfig.admin + '/order/filter-data';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.payment_status.map((item) => {
					payment_options_temp.push({ value: item.status, label: item.name });
					return true;
				});
				response.data.order_status.map((item) => {
					status_options_temp.push({ value: item.status, label: item.name });
					return true;
				});

				setOrderStatusOptions(status_options_temp);
				setPaymentStatusOptions(payment_options_temp);

				response.data.goal.map((item) => {
					goal_temp.push({ value: item.id, label: item.name });
					return true;
				});
				setGoalOptions(goal_temp);

			}
		});
	}

	/*---- filter data api --*/
	const basketfilterData = (index) => {

		let goal_temp = [];

		let url = devconfig.admin + '/basket-order/basket-filter';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {

				response.data.map((item) => {
					goal_temp.push({ value: item.id, label: item.label });
					return true;
				});
				setCartGoalOptions(goal_temp);

			}
		});
	}

	useEffect(() => {
		if (sessionStorage.getItem("order_status")) {
			setSelectedOrderStatus(JSON.parse(sessionStorage.getItem("order_status")));
			setFilterDown(true);
		} else if (orderStatusOptions.length > 0) {
			setSelectedOrderStatus([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("order_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("cart_order_status")) {
			setCartSelectedOrderStatus(JSON.parse(sessionStorage.getItem("cart_order_status")));
			setFilterDown(true);
		} else if (orderStatusOptions.length > 0) {
			setCartSelectedOrderStatus([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("cart_order_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("payment_status")) {
			setSelectedPayStatus(JSON.parse(sessionStorage.getItem("payment_status")));
			setFilterDown(true);
		} else if (paymentStatusOptions.length > 0) {
			setSelectedPayStatus([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("payment_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("cart_payment_status")) {
			setCartSelectedPayStatus(JSON.parse(sessionStorage.getItem("cart_payment_status")));
			setFilterDown(true);
		} else if (paymentStatusOptions.length > 0) {
			setCartSelectedPayStatus([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("cart_payment_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("goal")) {
			setSelectedGoal(JSON.parse(sessionStorage.getItem("goal")));
			setFilterDown(true);
		} else if (goalOptions.length > 0) {
			setSelectedGoal([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("goal")]);


	useEffect(() => {
		if (sessionStorage.getItem("cart_goal")) {
			setCartSelectedGoal(JSON.parse(sessionStorage.getItem("cart_goal")));
			setFilterDown(true);
		} else if (goalOptions.length > 0) {
			setCartSelectedGoal([]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("cart_goal")]);

	// useEffect(() => {
	// 	if (sessionStorage.getItem("page_num")) {
	// 		setPageNum(Number(JSON.parse(sessionStorage.getItem("page_num"))));
	// 	} else {
	// 		setPageNum(1);
	// 	}
	// 	//eslint-disable-next-line
	// }, [sessionStorage.getItem("page_num")]);

	useEffect(() => {
		if (sessionStorage.getItem("type")) {
			setType(JSON.parse(sessionStorage.getItem("type")));
			setFilterDown(true);
		} else {
			setType(typeOptions[0]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("type")]);

	useEffect(() => {
		if (sessionStorage.getItem("order_range")) {
			setDates(JSON.parse(sessionStorage.getItem("order_range")));
			setFilterDown(true);
		} else {
			setDates({
				start: null,
				end: null
			});
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("order_range")]);

	useEffect(() => {
		if (sessionStorage.getItem("cart_order_range")) {
			setCartDates(JSON.parse(sessionStorage.getItem("cart_order_range")));
			setFilterDown(true);
		} else {
			setCartDates({
				start: null,
				end: null
			});
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("cart_order_range")]);

	useEffect(() => {
		if (tabIndex === 1) {
			if (sessionStorage.getItem("order_search")) {
				setSearch(JSON.parse(sessionStorage.getItem("order_search")));
			} else {
				setSearch('');
			}
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("order_search"), tabIndex]);

	useEffect(() => {
		if (tabIndex === 2) {
			if (sessionStorage.getItem("cart_order_search")) {
				setSearch(JSON.parse(sessionStorage.getItem("cart_order_search")));
			} else {
				setSearch('');
			}
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("cart_order_search"), tabIndex]);

	/*---- order table columns -----*/

	const columns = [
		{
			name: 'Order ID',
			selector: (row) => row.order_id ? row.order_id : '--',
			sortable: true,
			left: true,
			minWidth: "110px",
			maxWidth: "110px",
			defaultSortAsc: false,
		},
		{
			name: 'Created on',
			selector: row => row.created,
			sortable: false,
			left: true,
			minWidth: '200px',
			// sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
			cell: row => <div className="col-lg-12" onClick={() => modalOpen(row)}>
				{row.order_time}
			</div>
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			left: true,
			width: '240px',
			cell: row => <>
				{
					row.name ?
						<div className='e-link' onClick={() => {
							navigate(`/user-detail?id=${row.customer_id}`, {
								state: {
									tab: 2
								}
							})
						}}>{row.name}</div>
						:
						"--"
				}
			</>
		},
		{
			name: 'Amount',
			selector: row => row.amount ? parseFloat(row.amount) : 0,
			// selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
			sortable: true,
			left: true,
			minWidth: '130px',
			cell: row => <div onClick={() => modalOpen(row)}>
				₹{
					row.amount ?
						row.amount.toFixed(2)
						:
						0
				}
			</div>

		},
		{
			name: 'Order status',
			selector: 'order_status',
			sortable: false,
			left: true,
			width: '140px',
			cell: row => <>{row.order_status ?
				<div className="d-inline-flex" onClick={() => modalOpen(row)}>
					<div className="col-lg-10">
						<div className={
							row.order_status === "Partially Success" ? `e-status-badge e-status-badge-partially` :
								row.order_status === "Success" ? `e-status-badge e-status-badge-success` :
									row.order_status === "Failed" ? `e-status-badge e-status-badge-fail` :
										row.order_status === "Processing" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{
								row.order_status
							}

						</div>
					</div>
				</div>
				:
				"--"}</>
		},
		{
			name: 'Retry',
			selector: row => row.is_retry ? row.is_retry : false,
			sortable: true,
			left: true,
			minWidth: '100px',
			cell: row => <div onClick={() => modalOpen(row)}>
				{
					row.is_retry ?
						"🔁"
						:
						"-"
				}
			</div>

		},
		{
			name: 'Pending',
			selector: row => row.pending_orders ? row.pending_orders : 0,
			sortable: true,
			left: true,
			width: '110px',
			cell: row => <div onClick={() => modalOpen(row)}>
				{
					row.order_status === "Partially Success" || row.order_status === "Failed" ?
						row.pending_orders ?
							row.pending_orders
							:
							0
						:
						"-"
				}
			</div>

		},
		{
			name: 'Payment status',
			selector: 'payment_status',
			sortable: false,
			left: true,
			minWidth: "160px",

			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: (row, key) => <>{row.payment_status ?
				<div className="d-inline-flex" onClick={() => modalOpen(row)}>
					<div className="col-lg-10">
						<div className={
							row.payment_status === "Success" ? `e-status-badge e-status-badge-success` :
								row.payment_status === "Failed" ? `e-status-badge e-status-badge-fail` :
									row.payment_status === "Processing" ? `e-status-badge e-status-badge-pending` :
										row.payment_status === "Submitted" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{row.payment_status.toLowerCase()}
						</div>
					</div>
					<div className="col-lg-2">
						<span onMouseEnter={() => setShow(key + 1)} onMouseLeave={() => setShow(null)} className={
							row.payment_status === "Failed" ? `e-icon` :
								row.payment_status === "Processing" ? `e-icon` :
									null
						}>

						</span>
					</div>
					{
						show === (key + 1) ?
							<Tooltip content={row.failure_reason ? row.failure_reason : row.payment_status === "Processing" ? "Transaction pending" : "Transaction failed"} />
							:
							null
					}
				</div>
				:
				"--"}</>
		},
		{
			name: tabIndex === 1 ? 'Goal name' : 'Basket/Fund',
			selector: row => row.goal_name,
			sortable: true,
			left: true,
			minWidth: '180px',
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.goal_name}
			</div>
		},
		{
			name: 'Type',
			selector: row => row.type,
			sortable: true,
			left: true,
			width: "180px",
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.type}
			</div>
		},

		{
			name: 'Payment method',
			sortable: false,
			left: true,
			minWidth: "180px",
			selector: "payment_method",
			cell: row => <div onClick={() => modalOpen(row)}>
				{
					row.payment_method === 1 ?
						"Net banking"
						:
						row.payment_method === 2 ?
							"UPI"
							: row.payment_method === 3 ?
								"Mandate"
								: "None"
				}
			</div>

		},

		{
			name: 'FP Payment ID',
			selector: (row) => row.fp_payment_id ? row.fp_payment_id : '--',
			sortable: false,
			left: true,
			width: "140px",
			defaultSortAsc: false,
			cell: row => <a href={`https://dashboard.fintechprimitives.com/live/resources/payments/${row.fp_payment_id}`} target="_blank">{row.fp_payment_id}</a>
		},
		{
			name: 'FP Order Ids',
			// cell: (row) => row.fp_order_ids ? row.fp_order_ids : '--',
			sortable: false,
			left: true,
			width: "280px",
			defaultSortAsc: false,
			style: {
				cursor: "pointer",
			},
			cell: row =>
				row.fp_order_ids.split(",").length > 0 && !row.fp_order_ids.split(",").includes("0") ?
					<div className='d-inline'>
						{

							row.fp_order_ids.split(",").map((item, index) =>
								<a className='py-0 pe-1' href={`https://dashboard.fintechprimitives.com/live/resources/purchases/${item}`}
									target="_blank" style={{ wordWrap: "break-word" }}>
									{`${item}${index !== row.fp_order_ids.split(",").length - 1 ? "," : ''}`}
								</a>
							)

						}

					</div>
					:
					"-"

		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: (row, key) => <div className="d-flex align-items-end" onClick={() => { setRefKay(key + 1); setMore(!more); }} ref={wrapperRef}>
				<span className="e-more" >
				</span>
				{
					more && (key + 1) === refKey ?
						<div className="e-card-transaction" ref={wrapperRef} onClick={() => modalOpen(row)}>
							<span >
								<img src={View} alt="View details" className="me-2" />View Details
							</span>
						</div>
						:
						null
				}

			</div>
		},
	]

	/*--- func to open order detail modal ---*/
	const modalOpen = (row) => {
		// setDetails(row);
		// var myModal = new Modal(document.getElementById("orderDetail"), {});
		// myModal.show();

		navigate("/order-detail", {
			state: {
				data: row,
			}
		})
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	const _handlePageChange = (page_num) => {
		setPageNum(page_num);
		if (tabIndex === 1) {
			getOrderList({ value: searchValue, page: page_num, per_page: perPage })
		} else {
			getCartOrderList({ value: searchValue, page: page_num, per_page: perPage })
		}
	};

	const _handlePerPage = (per_page) => {
		setPerPage(per_page);
		if (tabIndex === 1) {
			getOrderList({ value: searchValue, page: pageNum, per_page: per_page })
		} else {
			getCartOrderList({ value: searchValue, page: pageNum, per_page: per_page })
		}
	};

	/* --------selecting rows---------*/
	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['order_id']);
		}
		setCheck(selected);
	}

	const _handleTab = (temp) => {
		if (temp !== tabIndex) {
			setLoader(true);
			navigate("/orders", {
				state: {
					index: temp
				}
			})
			setTabindex(temp);
			setPageNum(1);
			setPerPage(10);
			setSearch("");
		}
	}

	/*--------- order list -----------*/
	const getOrderList = (query) => {
		setCheck([])
		let url = devconfig.admin + '/order/list';
		if (query) {
			setPageNum(query.page);
			setPerPage(query.per_page);
			if (query.value !== "") {
				setSearchValue(query.value);
			}
			setSearch(query.value);
		}
		let data = JSON.stringify({
			from_date: dates.start,
			to_date: dates.end,
			type: type.value,
			payment_status: selectedPayStatus.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? selectedPayStatus.filter((filter) => filter.value !== null).map((item) => item.value) : null,
			order_status: selectedOrderStatus.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? selectedOrderStatus.filter((filter) => filter.value !== null).map((item) => item.value) : null,
			query: query ? (query.value) : search,
			query: query ? (query.value) : search,
			page_num: query ? (query.page) : pageNum,
			page_size: query ? (query.per_page) : perPage,
			goal_id: selectedGoal.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? selectedGoal.filter((filter) => filter.value !== null).map((item) => item.value) : null,

		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data.data);
				setTotalRecords(response.data.total_records)
				setTotalValue(response.data.total_value);
				setSummaryData(response.data);
				setCheck([]);
				setLoader(false);
			} else {
				setLoader(false)
				setOrderData([]);
			}
		});
	}

	/*--------- order list -----------*/
	const getCartOrderList = (query) => {
		setCheck([])
		let url = devconfig.admin + '/basket-order/order-list';
		if (query) {
			setPageNum(query.page);
			setPerPage(query.per_page);
			if (query.value !== "") {
				setSearchValue(query.value)
			}
			setSearch(query.value);
		}

		let data = JSON.stringify({
			from_date: cartDates.start,
			to_date: cartDates.end,
			payment_status: cartSelectedPayStatus.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? cartSelectedPayStatus.filter((filter) => filter.value !== null).map((item) => item.value) : null,
			order_status: cartSelectedOrderStatus.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? cartSelectedOrderStatus.filter((filter) => filter.value !== null).map((item) => item.value) : null,
			query: query ? (query.value) : search,
			page_num: query ? (query.page) : pageNum,
			page_size: query ? (query.per_page) : perPage,
			goal_id: cartSelectedGoal.filter((filter) => filter.value !== null).map((item) => item.value).length > 0 ? cartSelectedGoal.filter((filter) => filter.value !== null).map((item) => item.value) : null,

		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data.data);
				setTotalRecords(response.data.total_records)
				// setTotalValue(response.data.total_value);
				setLoader(false);
			} else {
				setLoader(false)
				setOrderData([]);
			}
		});
	}


	const _handleShowSummary = (status) => {
		setShowSummary(status);
	}

	/*---- download orders--*/
	const downloadOrderList = (status) => {
		setDownloadLoader(true);
		let url = devconfig.admin + '/order/list-download';
		let data = JSON.stringify({
			user_id: Number(props.id),
			order_ids: check.length === orderData.length ? [] : check,
			is_cart: status
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setDownloadLoader(false);
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
					setSuccess("List downloaded successfully")
				} else {
					setDownloadLoader(false);
					setError(response.message)
				}
			}
			else {
				setDownloadLoader(false);
				setError(response.message);
			}
		})
	}

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0 py-1">
						{
							loader ?
								<Loader />
								:
								<div className="pt-2">
									<div className='row align-items-center'>
										<div className="col-lg-10">
											<FundsTab data={["Orders", "Cart orders"]}
												index={tabIndex}
												setTab={(temp) => {
													_handleTab(temp)
												}} />
										</div>


										{tabIndex === 2 && <div className="col-lg-2 col-2">
											<PrimaryBtn name="Create Cart order"
												handleClick={() => {
													navigate("/orders/create-cart-order")
												}} />
										</div>}
									</div>

									<div className="row pt-2">
										{Object.keys(summaryData).length > 0 && tabIndex === 1 && <div className='col-12 pb-1 pt-2'>
											<div className='d-flex align-items-center e-horizontal-scroll'>
												<label className='d-block text-start me-2'>
													<p className='mb-2 opacity-0 border-bottom'>.</p>
													<div>Lumpsum</div>
													<div>SIP</div>
													<div>Withdrawal</div>
													<div>Total</div>
												</label>
												<label className='d-block text-center font-weight-600 mx-2 px-2'>
													<p className='mb-2 border-bottom'>Total</p>
													<div>{summaryData.total_data.total_lumpsum_order}</div>
													<div>{summaryData.total_data.total_sip_order}</div>
													<div>{summaryData.total_data.total_redeem_order}</div>
													<div>{summaryData.total_data.final_total}</div>
												</label>

												{
													summaryData.goal_wise_data.map((item) => {
														return (
															<label className='d-block text-end mx-2 px-2'>
																<p className='border-bottom mb-2'>{item.goal_name}</p>
																<div>{item.basket_lumpsum_amount} ({item.lumpsum_order_count})</div>
																<div>{item.basket_sip_amount} ({item.sip_order_count})</div>
																<div>{item.basket_redemption_amount} ({item.redemption_order_count})</div>
																<div>{item.basket_total_amount} ({item.total_basket_records})</div>
															</label>
														)
													})
												}
											</div>
										</div>}

										{/*-------------- Filters  ---------------*/}
										{
											filterDown ?
												<div className="e-filter-wraper e-sort mt-1">
													{
														tabIndex === 1 ?
															<div className="row align-items-end">
																<div className="col-lg-2 pe-1 col-4 ">
																	<label className="mb-1">Transactions from - till</label>
																	<DateRangePicker
																		onDatesChange={(start, end) => {
																			setPageNum(1);
																			setPerPage(10);
																			setDates({ start: start, end: end });
																			if (start === null && end === null) {
																				sessionStorage.removeItem("order_range");
																			} else {
																				sessionStorage.setItem("order_range", JSON.stringify({ start: start, end: end }));
																			}
																		}}
																		start={dates.start}
																		end={dates.end}
																	/>
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Order status</label>
																	<CustomMultiSelect data={orderStatusOptions}
																		selected={selectedOrderStatus}
																		placeHolder="Select order status"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setSelectedOrderStatus(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("order_status");
																			} else {
																				sessionStorage.setItem("order_status", JSON.stringify(list));
																			}
																		}}
																	/>
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Payment status</label>
																	<CustomMultiSelect data={paymentStatusOptions}
																		selected={selectedPayStatus}
																		placeHolder="Select payment status"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setSelectedPayStatus(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("payment_status");
																			} else {
																				sessionStorage.setItem("payment_status", JSON.stringify(list));
																			}
																		}} />
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Goal/Basket</label>
																	<CustomMultiSelect
																		selected={selectedGoal}
																		data={goalOptions}
																		placeHolder="Select Goal/Basket"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setSelectedGoal(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("goal");
																			} else {
																				sessionStorage.setItem("goal", JSON.stringify(list));
																			}
																		}}
																	/>
																</div>
																<div className="col-lg-2 px-1 col-3">
																	<label className="mb-1">Type</label>
																	<SelectBox
																		value={type}
																		options={typeOptions}
																		selectchange={(temp) => {
																			setPageNum(1);
																			setPerPage(10);
																			setType(temp);
																			if (temp.value === null) {
																				sessionStorage.removeItem("type")
																			} else {
																				sessionStorage.setItem("type", JSON.stringify(temp))
																			}
																		}}
																		isSearchable={false}
																	/>
																</div>

																<div className="col-lg-2 ps-1 position-relative mt-3">
																	<div className='d-flex align-items-center bg-white p-1'>
																		<div className='position-relative'>
																			<SearchInput
																				placeholder="Search with MIRA Order ID, Name and Goal"
																				value={search}
																				valueChange={(value) => {
																					setSearch(value);
																					if (value === "") {

																						getOrderList({
																							value: "",
																							page: pageNum,
																							per_page: perPage
																						});
																						sessionStorage.removeItem("order_search");
																					}
																				}}
																				onSearch={(value) => {
																					getOrderList({
																						value: value,
																						page: 1,
																						per_page: 10
																					});
																					sessionStorage.setItem("order_search", JSON.stringify(value));

																				}}
																			/>
																			{
																				search && search.length ?
																					<>
																						<span
																							className={`${styles.e_search_submit} `}
																							onClick={() => {
																								getOrderList()
																							}}>
																						</span>
																						<button
																							className={`${styles.e_search_close} pe-3`}
																							onClick={() => {
																								setSearch("");
																								getOrderList({
																									value: "",
																									page: 1,
																									per_page: 10
																								});
																								sessionStorage.removeItem("order_search");
																							}}>
																						</button>
																					</>
																					:
																					null
																			}

																		</div>
																		<DownloadBtn
																			check={["check"]}
																			loader={downloadLoader}
																			onClick={() => {
																				downloadOrderList(false);
																			}}
																		/>
																	</div>
																</div>

															</div>
															:
															<div className="row align-items-end">
																<div className="col-lg-2 pe-1 col-4 ">
																	<label className="mb-1">Transactions from - till</label>
																	<DateRangePicker
																		onDatesChange={(start, end) => {
																			setPageNum(1);
																			setPerPage(10);
																			setCartDates({ start: start, end: end });
																			if (start === null && end === null) {
																				sessionStorage.removeItem("cart_order_range");
																			} else {
																				sessionStorage.setItem("cart_order_range", JSON.stringify({ start: start, end: end }));
																			}
																		}}
																		start={cartDates.start}
																		end={cartDates.end}
																	/>
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Order status</label>
																	<CustomMultiSelect data={orderStatusOptions}
																		selected={cartSelectedOrderStatus}
																		placeHolder="Select order status"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setCartSelectedOrderStatus(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("cart_order_status");
																			} else {
																				sessionStorage.setItem("cart_order_status", JSON.stringify(list));
																			}
																		}}
																	/>
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Payment status</label>
																	<CustomMultiSelect data={paymentStatusOptions}
																		selected={cartSelectedPayStatus}
																		placeHolder="Select payment status"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setCartSelectedPayStatus(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("cart_payment_status");
																			} else {
																				sessionStorage.setItem("cart_payment_status", JSON.stringify(list));
																			}
																		}} />
																</div>
																<div className="col-lg-2 px-1 col-3 ">
																	<label className="mb-1">Basket</label>
																	<CustomMultiSelect
																		selected={cartSelectedGoal}
																		data={cartGoalOptions}
																		placeHolder="Select Basket"
																		select={(list) => {
																			setPageNum(1);
																			setPerPage(10);
																			setCartSelectedGoal(list);
																			if (list.length === 0) {
																				sessionStorage.removeItem("cart_goal");
																			} else {
																				sessionStorage.setItem("cart_goal", JSON.stringify(list));
																			}
																		}}
																	/>
																</div>

																<div className="col-lg-3 ps-1 position-relative mt-3">
																	<div className='d-flex align-items-center bg-white p-1'>
																		<div className='position-relative'>
																			<SearchInput
																				placeholder="Search with MIRA Order ID, Name and Goal"
																				value={search}
																				valueChange={(value) => {
																					setSearch(value);
																					if (value === "") {
																						getCartOrderList({
																							value: "", page: pageNum, per_page: perPage
																						});
																						sessionStorage.removeItem("cart_order_search");
																					}
																				}}
																				onSearch={(value) => {
																					if (value.length > 0) {
																						getCartOrderList({ value: value, page: 1, per_page: 10 });
																					}
																					sessionStorage.setItem("cart_order_search", JSON.stringify(value));

																				}}
																			/>
																			{
																				search && search.length ?
																					<>
																						<span
																							className={`${styles.e_search_submit} `}
																							onClick={() => {
																								getCartOrderList()
																							}}>
																						</span>
																						<button
																							className={`${styles.e_search_close} pe-3`}
																							onClick={() => {
																								setSearch("");
																								getCartOrderList({
																									value: "",
																									page: 1,
																									per_page: 10
																								});
																								sessionStorage.removeItem("cart_order_search");
																							}}>
																						</button>
																					</>
																					:
																					null
																			}

																		</div>
																		<DownloadBtn
																			check={["check"]}
																			loader={downloadLoader}
																			onClick={() => {
																				downloadOrderList(true);
																			}}
																		/>
																	</div>
																</div>

															</div>
													}
												</div>
												:
												null
										}

										{/*--------------- Order table -----------*/}
										{
											tabIndex === 1 ?
												(!orderData.length) ?
													<EmptyNote note="There are no orders to show" />
													:
													<div className="col-lg-12 px-0">
														<DataTable
															pagination={true}
															columns={columns}
															data={orderData}
															perPage={perPage}
															paginationServer={true}
															selectableRows={true}
															paginationDefaultPage={pageNum}
															clearSelectedRows={downloadLoader ? true : false}
															paginationTotalRows={totalRecords}
															onChangeRowsPerPage={_handlePerPage}
															onChangePage={_handlePageChange}
															selectRows={(row) => { handleChange(row) }}
															rowClick={(row) => modalOpen(row)}
														/>
													</div>
												:
												(!orderData.length) ?
													<EmptyNote note="There are no orders to show" />
													:
													<div className="col-lg-12 px-0">
														<DataTable
															pagination={true}
															columns={columns}
															data={orderData}
															perPage={perPage}
															paginationServer={true}
															selectableRows={true}
															paginationDefaultPage={pageNum}
															clearSelectedRows={downloadLoader ? true : false}
															paginationTotalRows={totalRecords}
															onChangeRowsPerPage={_handlePerPage}
															onChangePage={_handlePageChange}
															selectRows={(row) => { handleChange(row) }}
															rowClick={(row) => modalOpen(row)}
														/>
													</div>
										}
									</div>
								</div>
						}
					</div>

					{tabIndex === 1 && <h6 className='mt-3 text-end'>Total value: {totalValue.toLocaleString('en-IN')}</h6>}
				</div>
			</div>

			{/*--------- Ordetdetaiil modal component ----------------*/}

			<div ref={wrapperRef}>
				<OrderDetail
					data={details}
					updateList={() => getOrderList("")}
					order_status={details.order_status}
					payment_status={details.payment_status}
				/>
			</div>

			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="eror"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}
			{
				showSummary === true && <SummaryModal
					closeModal={() => _handleShowSummary(false)}
					title="Order"
					type={2}
					data={summaryData} />
			}
			<Toast />
		</>
	);
}

export default Index;

