/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-12-2021
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'

/*import style*/
import styles from '../../styles/components/sidebar.module.scss';

/*import config*/
import devconfig from '../../config/config';

/*import assets*/
import Logo from '../../assets/images/navigation/inner-logo.png';
import InnerLogo from '../../assets/images/menu/inner-logo.svg';

export default function Sidebar(props) {
	//eslint-disable-next-line
	const [location, setLocation] = useState();
	const current = useLocation();

	useEffect(() => {
		setLocation(current)
		if (current.pathname === '/user-detail') {
			setLocation({ pathname: '/users' })
		}
		if (current.pathname === '/order-detail' || current.pathname.includes("/orders")) {
			setLocation({ pathname: '/orders' })
		}
	}, [current]);


	useEffect(() => {

		const handleNavigation = () => {
			const modalBackdrops = document.querySelectorAll('.modal-backdrop');

			if (modalBackdrops.length > 0) {
				modalBackdrops.forEach((backdrop) => backdrop.remove());
			}

			document.body.removeAttribute('style');
			document.body.removeAttribute('class');
		};

		handleNavigation();

		// Add event listener for the 'popstate' event
		window.addEventListener('popstate', handleNavigation);

		// Clean up the event listener when the component is unmounted
		return () => {
			window.removeEventListener('popstate', handleNavigation);
		};

	}, []);

	return (
		<>
			<div className={props.is_menu_collapse || props.width <= 1024 ? `${styles.e_sidebar_collapsed} ${styles.e_sidebar}` : `${styles.e_sidebar}`}>
				{
					!props.is_menu_collapse && props.width > 1024 ?
						<div className={`${styles.e_sidebar_list_wrap} position-relative`}>
							<div className={`${styles.e_logo_wrapper} mb-3`}>
								<Link to="/">
									<img src={Logo} className={`${styles.e_logo}`} alt="logo" />
								</Link>
							</div>
							<div className={`${styles.e_sidebar_list}`}>
								{
									devconfig.sidebar.map((item, key) => {
										return (
											<Link to={item.link} key={item.name}>
												<div style={{ marginTop: `${item.style}px` }} className={location?.pathname === item.link ? `${styles.e_sidebar_item_active}` : `${styles.e_sidebar_item}`}>
													<img src={location?.pathname === item.link ? item.activeIcon : item.icon} alt="" style={location?.pathname === item.link ? { width: item.size, height: item.size, position: "relative" } : { width: item.size, height: item.size, position: "relative" }} />
													<span>{item.name}</span>
												</div>
											</Link>
										)
									})
								}
							</div>
						</div>
						:
						<div >
							<div className={` text-center mb-4 mt-4`}>
								<Link to="/"><img src={InnerLogo} alt="" height="24px" /></Link>
							</div>
							<div>
								{
									devconfig.sidebar.map((item, key) => {
										return (
											<Link to={item.link} key={item.name}>
												<div className={location?.pathname === item.link ? `${styles.e_sidebar_item_collapse_active}` : `${styles.e_sidebar_collpase_item}`}>
													<img src={location?.pathname === item.link ? item.collapseActiveIcon : item.icon} alt="" style={location?.pathname === item.link ? { top: item.space, left: item.left, padding: item.paddingCollapse } : { width: item.lengthCollapse, height: item.lengthCollapse, padding: item.padding }} />
												</div>
											</Link>
										)
									})
								}
							</div>
						</div>
				}
			</div>
		</>
	)
}

