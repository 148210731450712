
/*
 *   File : gameTab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-02-2022
*/

/*import package*/
import { useEffect, useState } from 'react';

/*import styles*/
import styles from '../../styles/components/fundTab.module.scss';

const FundsTab = (props) => {
    const [index, setIndex] = useState(props.index);

    useEffect(() => {
        setIndex(props.index);
    }, [props.index])
    return (
        <div className="d-flex">
            {
                props.funds ?
                    <>
                        {
                            props.data.map((item, key) => {
                                return (
                                    <span
                                        className={item.id === index ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                        onClick={() => { setIndex(item.id); props.setTab(item.id, item.name, item.goal) }} key={key}
                                    >
                                        {item.displayName}
                                    </span>
                                )
                            })
                        }
                    </>
                    :
                    <>
                        {
                            props.data.map((item, key) => {
                                if (props.version && item.id == 4) {
                                    return null
                                }
                                return (
                                    <span
                                        className={index === (key + 1) ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                        onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
                                    >
                                        {item}
                                    </span>
                                )
                            })
                        }
                    </>

            }

        </div>
    )
}

export default FundsTab;

