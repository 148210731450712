/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/input.module.scss';

const AmountInput = (props) => {

    const [value, setValue] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue]);

    /* --- validation for email , number (10 digits)----*/
    const validate = () => {
        if (props.type === "email") {
            //eslint-disable-next-line
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                setError("Invalid email")
            }
        }
        else if (props.type === "number" && (props.placeholder === "Phone Number" || props.placeholder === "Phone number" || props.placeholder === "Mobile Number")) {
            if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) {
                setError("Invalid phone")
            }
        }
        if (props.validateLength) {
            if (props.minLength && (value.length < props.minLength)) {
                setError(`Minimum ${props.minLength} charcter${props.minLength > 1 ? "s" : ""} required`);
                props?.valueChange(null);
            }
            else if (value.length > props.maxLength) {
                setError(`Maximum charcter limit(${props.maxLength}) exceeded.`);
                props?.valueChange(null);
            }
        }
        if (props.onlyNumber) {
            if (!/^\d+$/.test(value)) {
                setError("Enter a valid number");
                props?.valueChange(null);
            }
        }
    }

    /* ---- onChange event for inputs and passing value in terms of props ---*/
    const valueChange = (e) => {
        const input_value = e.target.value;

        // Removing space and comma
        const formatted_value = Number(input_value.replaceAll(",", ''));

        const maxValue = props.maxValue || 999999999;

        if (isNaN(formatted_value) === false && formatted_value > 0) {

            if (props.type === 2 && (formatted_value <= maxValue)) {
                props.valueChange(formatted_value);
            } else {
                if ((formatted_value <= maxValue)) {
                    props.valueChange(formatted_value);
                }
            };
        } else {
            // eslint-disable-next-line
            if (input_value == 0 && input_value.length <= 1 && props.is_zero === true) {
                props.valueChange(input_value);
            } else {
                if (input_value.length <= 1) {
                    props.valueChange('');
                } else {
                    e.preventDefault();
                }

            }
        }
    }


    /* ---- func to be implemented on press of enter ----*/
    const keyPress = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey;

        if (key_code === 9 || // Tab key
            (is_ctrl_pressed && (key_code === 65 || key_code === 67 || key_code === 88 || key_code === 86))
        ) {
            return; // Allow Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            return; // Allow the keypress
        } else {
            e.preventDefault();
        }
    }

    return (
        <div className={`${styles.e_input_wrap} position-relative ${props.wrapperClass}`}>

            <p className={`${styles.e_prefix} position-absolute mb-0`}>
                ₹
            </p>
            <input id={props.id}
                value={value && value.toLocaleString('en-IN')}
                type={"text"}
                //eslint-disable-next-line
                className={error ? `${styles.e_input_error} ${styles.e_input_box}` + " " + props.className : `${styles.e_input_box}` + " " + props.className}
                onChange={(e) => { valueChange(e) }}
                placeholder={props.placeholder}
                required={props.required}
                onBlur={() => { validate() }}
                style={{ paddingLeft: "20px", width: "maxContent" }}
                // onFocus={() => setValue(props.value)}
                prefix={props.prefix}
                onKeyDown={e => keyPress(e)}
                name={props.name}
                min={props.min}
                step={props.step}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onWheel={() => document.activeElement.blur()} />


            {
                props.suffix ?
                    <span className={styles.e_suffix}>{props.suffix}</span>
                    :
                    null
            }
            {
                props.preffix ?
                    <span className={styles.e_preffix}>{props.preffix}</span>
                    :
                    null
            }
            {props.label ? <label htmlFor={props.id} className="label">{props.label}</label> : null}
            {
                error ?
                    <p className={styles.e_error_msg + " mb-0"}>{error}</p>
                    :
                    null
            }
        </div>
    );
}
export default AmountInput;

