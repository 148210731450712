/*
 *   File : create-cart-order.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :Create card order page
 *   Integrations : null
 *   Version : v1.0
 */

// packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";

// components
import { AmountInput, Input, SIPDateModal } from "../../components/input";
import { Toast } from "../../components/note";
import { PrimaryBtn, TextBtn } from "../../components/cta";

// assets
import AddNew from '../../assets/images/dataFormating/add-new.svg';

// config,services
import devconfig from "../../config/config";
import APIPost from "../../services/postApi";
import APIGet from "../../services/getApi";
import { toast, ToastContainer } from "react-toastify";
import { SuccessConfirmationModal } from "../../components/modal";


const CreateCartOrder = () => {
    const navigate = useNavigate();
    const searchRef = useRef(null);
    const initialData = useRef([]);
    const today = new Date()
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [fundsList, setFundsList] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [sipChecked, setSipChecked] = useState(false);
    const [amount, setAmount] = useState(0);
    const [showDateModal, setShowDateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedBaskets, setSelectedBaskets] = useState([]);
    const [allocationData, setAllocationData] = useState([]);
    const [detail, setDetail] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [addedFunds, setAddedFunds] = useState([]);
    const [orderSent, setOrderSent] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (today < 28) {
            setSelectedDate(today + 1);
        } else {
            setSelectedDate(1);
        }
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, [])


    useEffect(() => {
        if (showDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [showDateModal]);

    useEffect(() => {
        if (orderSent === true) {
            const modal = new Modal(document.getElementById("confirmSuccess"), {});
            modal.show();
        }
    }, [orderSent]);


    const _closeMenu = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    }

    function _handleDateModal(status) {
        setShowDateModal(status)
    }

    function _handleSelect(obj, obj_index) {
        if (amount > 0) {
            const selected_baskets = [...selectedBaskets]
            const basket_data = [...allocationData]
            const index = selected_baskets.findIndex((item) => item.id === obj.id);
            if (index === -1) {
                selected_baskets.push(obj)
            } else {
                selected_baskets.splice(index, 1)
                basket_data[obj_index].allocation = 0
                basket_data[obj_index].amount = ""
            }
            setSelectedBaskets(selected_baskets)
            setAllocationData(basket_data);

        } else {
            toast.dismiss()
            toast.error("Please enter investment amount")
        }
    }

    function _handleAllocation(input_value, id, obj_index) {
        const selected_baskets = [...selectedBaskets]
        const basket_data = [...allocationData]
        const index = selected_baskets.findIndex((item) => item.id === id);
        if (input_value <= 100) {
            selected_baskets[index].allocation = input_value;
            basket_data[obj_index].allocation = input_value;
            selected_baskets[index].amount = ((input_value / 100) * amount)
            basket_data[obj_index].amount = ((input_value / 100) * amount)
        }
        setSelectedBaskets(selected_baskets)
        setAllocationData(basket_data);
    }

    // function _handleSip(input_value, id, obj_index, min) {
    //     const selected_baskets = [...selectedBaskets]
    //     const basket_data = [...allocationData]
    //     const index = selected_baskets.findIndex((item) => item.id === id);
    //     selected_baskets[index].amount = input_value
    //     basket_data[obj_index].amount = input_value;
    //     setSelectedBaskets(selected_baskets)
    //     setAllocationData(basket_data);
    // }

    function _handleBasketAmount(input_value, id, obj_index, min) {
        const selected_baskets = [...selectedBaskets]
        const basket_data = [...allocationData]
        const index = selected_baskets.findIndex((item) => item.id === id);
        if (input_value <= amount) {
            selected_baskets[index].amount = input_value;
            basket_data[obj_index].amount = input_value;
            selected_baskets[index].allocation = input_value > 0 ? (parseFloat((input_value / amount) * 100)).toFixed(2) : 0
            basket_data[obj_index].allocation = input_value > 0 ? (parseFloat((input_value / amount) * 100)).toFixed(2) : 0
        }
        setSelectedBaskets(selected_baskets)
        setAllocationData(basket_data);
    }

    // handle sip date
    function _handleSelectDate(date) {
        setSelectedDate(date);
    }

    function _handleFundSelect(value, index, code, obj) {
        const funds = [...addedFunds]
        funds[index].scheme_name = value;
        funds[index].scheme_code = code;
        funds[index].min_lumpsum_investment = obj.min_lumpsum_investment
        funds[index].min_sip_investment = obj.min_sip_investment
        funds[index].allocation = 0
        funds[index].amount = ""
        setAddedFunds(funds)
    }

    function _handleFundAllocation(value, index,) {
        const funds = [...addedFunds]
        if (value <= 100) {
            funds[index].allocation = value;
            if (sipChecked) {
                funds[index].amount = ((value / 100) * amount)
            } else {
                funds[index].amount = ((value / 100) * amount)
            }
        }
        setAddedFunds(funds)
    }

    function _handleFundAmount(value, index, code) {
        const funds = [...addedFunds]
        if (value <= amount) {
            funds[index].amount = value;
            funds[index].allocation = value > 0 ? (parseFloat((value / amount) * 100)).toFixed(2) : 0

        }
        setAddedFunds(funds)
    }



    function _handleAdd() {
        if (amount > 0) {
            const funds = [...addedFunds]

            funds.push({
                scheme_name: "",
                scheme_code: "",
                allocation: 0,
                min_lumpsum_investment: "",
                min_sip_investment: "",
                amount: ""
            })
            setAddedFunds(funds)
        } else {
            toast.dismiss();
            toast.error("Please enter investment amount");
        }
    }

    function _getTotalAllocation() {
        const sum1 = selectedBaskets.reduce((a, b) => a + parseFloat(b.allocation), 0);
        const sum2 = addedFunds.reduce((a, b) => a + parseFloat(b.allocation), 0);
        return parseFloat(sum1) + parseFloat(sum2)
    }

    function _getTotalAmount(type) {
        console.log(selectedBaskets)
        const sum1 = selectedBaskets.filter((basket) => basket.amount > 0).reduce((a, b) => (a) + (b.amount), 0);
        const sum2 = addedFunds.filter((fund) => fund.amount > 0).reduce((a, b) => (a) + (b.amount), 0);
        let finalSum = parseFloat(sum1 ? sum1 : 0) + parseFloat(sum2 ? sum2 : 0);

        if (type === 1) {
            return finalSum.toLocaleString('en-IN')
        } else {
            return finalSum
        }

    }


    function _handleSubmit() {
        toast.dismiss();
        const selected_baskets = [...selectedBaskets]

        if (sipChecked) {
            if (amount === "" || amount < 0) {
                toast.error(`Please enter investment amount`);
                return false;
            }
        } else {
            if (amount === "" || amount < 0) {
                toast.error(`Please enter investment amount`);
                return false;
            }
        }

        if (selectedBaskets.length > 0) {
            for (let i = 0; i < selected_baskets.length; i++) {
                const element = selected_baskets[i];
                if (sipChecked) {
                    if (element.amount < element.min_sip_amount) {
                        toast.error(`${element.basket}'s SIP cannot be less than minimum amount`);
                        return false;
                    }
                } else {
                    if (element.amount < element.min_lumpsum_amount) {
                        toast.error(`${element.basket}'s Lumpsum cannot be less than minimum amount`);
                        return false;
                    }
                }
            }
        }
        if (addedFunds.length > 0) {
            for (let i = 0; i < addedFunds.length; i++) {
                const element = addedFunds[i];
                if (sipChecked) {
                    if (element.scheme_code === "") {
                        toast.error(`Please select a fund to proceed with SIP`);
                        return false;
                    }
                    if (element.amount < element.min_sip_investment) {
                        toast.error(`${element.scheme_name}'s SIP cannot be less than minimum amount`);
                        return false;
                    }
                } else {
                    if (element.scheme_code === "") {
                        toast.error(`Please select a fund to proceed with Lumpsum`);
                        return false;
                    }
                    if (element.amount < element.min_lumpsum_investment) {
                        toast.error(`${element.scheme_name}'s Lumpsum cannot be less than minimum amount`);
                        return false;
                    }
                }

            }
        }
        if (_getTotalAllocation() !== 100) {
            toast.error(`Total allocation must be 100%`);
            return false;
        }
        if (_getTotalAmount(2) !== amount) {
            toast.error(`Total allocated amount is not matching with Invested amount`);
            return false;
        }
        _createOrder();
    }

    function _handleChecked(status) {
        // _getBasketData(detail.id)
        setSipChecked(status);
        // let added_funds = [...addedFunds]
        // if (status) {
        //     setAmount(amount);
        // } else {
        //     setAmount(amount);
        // }
        // if (added_funds.length > 0) {
        //     added_funds.map((element) => {
        //         element.scheme_name = ""
        //         element.scheme_code = ""
        //         element.allocation = 0
        //         element.sip = ""
        //         element.min_lumpsum_investment = ""
        //         element.min_sip_investment = ""
        //         element.amount = ""
        //     })
        //     setAddedFunds(added_funds)
        // }
    }

    function _selectCustomer(item) {
        setSelectedCustomer(item);
        setShowDropdown(false);
        _getBasketData(item.id, true);
    }

    function _showSuccessModal(status) {
        setOrderSent(status)
    }

    function _handleAmount(input_value) {

        setAmount(input_value);
        // const basket_list = initialData.current.map((item) => {
        //     return ({
        //         allocation: ((item.min_sip_amount / input_value) * 100).toFixed(0),
        //         ...item
        //     })
        // })
        // setAllocationData(basket_list);
        // let added_funds = [...addedFunds]
        // if (added_funds.length > 0) {
        //     added_funds.map((element) => {
        //         element.scheme_name = ""
        //         element.scheme_code = ""
        //         element.allocation = 0
        //         element.sip = ""
        //         element.min_lumpsum_investment = ""
        //         element.min_sip_investment = ""
        //         element.amount = ""
        //     })
        //     setAddedFunds(added_funds)
        // }
        // setSelectedBaskets([]);
    }

    function _handleDelete(index) {
        const added_funds = [...addedFunds]
        added_funds.splice(index, 1);
        setAddedFunds(added_funds)
    }

    function _handleClose() {
        setOrderSent(false);
        setShowDetail(false);
        setSelectedBaskets([]);
        setAddedFunds([]);
        setOrderSent(false);
        window.scrollTo(0, 0);
        setSelectedCustomer(null);
        setAmount(0);
        setSipChecked(false);
        if (today < 28) {
            setSelectedDate(today + 1);
        } else {
            setSelectedDate(1);
        }
    }

    function _viewOrders() {
        navigate(-1);
    }

    function _handleBack() {
        setShowDetail(false);
        setSelectedCustomer(null);
        setAmount(0);
        setAmount(0);
        setSipChecked(false);
        if (today < 28) {
            setSelectedDate(today + 1);
        } else {
            setSelectedDate(1);
        }
    }

    function _clearAll() {
        setSelectedBaskets([]);
        setAddedFunds([]);
        setAmount(0);
        _getBasketData(detail.id);
    }


    const _formatSip = (selected_date) => {
        switch (selected_date) {
            case 1: return "1st";
            case 2: return "2nd";
            case 3: return "3rd";
            case 21: return "21st";
            case 22: return "22nd";
            case 23: return "23rd";
            default: return selected_date + "th";
        };
    }

    // API - customer list
    const _getCustomerFilterData = (query = null) => {
        setSelectedCustomer(null);
        let api_url = devconfig.admin + '/basket-order/customer-filter-data';
        let data = JSON.stringify({
            query: query
        });
        APIPost(api_url, data).then((response) => {
            if (response.status_code === 200) {
                setShowDropdown(true);
                setCustomerList(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
        });
    }

    // API - fund list
    const _getFunds = (query = null, index) => {
        let api_url = devconfig.admin + '/fund/search';
        let sample_query = query.length > 0 ? query.replace(/\s*-\s*/g, " ") : ""
        let data = JSON.stringify({
            query: sample_query.toLowerCase().trim(),
            page_num: 1,
        });
        APIPost(api_url, data).then((response) => {
            if (response.status_code === 200) {
                setShowDropdown(index);
                setFundsList(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message);
                setShowDropdown(null);
                setFundsList([]);
            }
        });
    }

    // API - fund detail
    const _getFundDetail = (fund, index) => {
        let api_url = devconfig.admin + '/fund/custom-fund';
        let data = JSON.stringify({
            isin: fund.isin
        });
        APIPost(api_url, data).then((response) => {
            if (response.status_code === 200) {
                _handleFundSelect(fund.scheme_name, index, fund.scheme_code, fund)
                setShowDropdown(false);
            } else {
                _handleFundSelect("", index, "", fund)
                toast.dismiss();
                toast.error(response.message);
            }
        });
    }

    // API - get user details
    const _getDetail = (temp) => {
        let url = devconfig.admin + '/basket-order/customer-detail';
        let data = JSON.stringify({
            "customer_id": parseInt(temp),
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                let detail = {
                    id: temp,
                    ...response.data
                }
                setDetail(detail);
                setShowDetail(true);
            }
            else {
                setDetail({})
            }
        })
    }

    // API - create basket order 
    const _createOrder = () => {
        setBtnLoader(true);
        let api_url = devconfig.admin + '/basket-order/set';

        const basket_data = selectedBaskets.map((item) => {
            return ({
                "goal_id": item.id,
                "lumpsum": sipChecked === false ? {
                    "amount": item.amount,
                } : null,
                "sip": sipChecked === true ? {
                    "amount": item.amount,
                    "sip_date": selectedDate
                } : null
            })
        })

        const custom_funds = addedFunds.map((item) => {
            return ({
                "scheme_code": item.scheme_code,
                "lumpsum": sipChecked === false ? {
                    "amount": item.amount
                } : null,
                "sip": sipChecked === true ? {
                    "amount": item.amount,
                    "sip_date": selectedDate
                } : null
            })
        })

        let data = JSON.stringify({
            "customer_id": selectedCustomer.id,
            "baskets": basket_data.length === 0 ? null : basket_data,
            "funds": custom_funds.length === 0 ? null : custom_funds
        })
        APIPost(api_url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setBtnLoader(false);
                _showSuccessModal(true)
            } else {
                toast.error(response.message);
            }
            setBtnLoader(false);
        })
    }

    // API - get basket data
    const _getBasketData = (id, is_first) => {
        setSelectedBaskets([]);
        const url = devconfig.admin + "/basket-order/basket-list";
        let data = JSON.stringify({
            "customer_id": parseInt(id),
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (is_first) {
                    _getDetail(id);
                }
                const basket_list = response.data.map((item) => {
                    // const percent = (((checked ? item.min_sip_amount : item.min_lumpsum_amount) / (checked ? amount : amount)) * 100)
                    return ({
                        basket: item.goal_name,
                        allocation: 0,
                        amount: "",
                        id: item.id,
                        ...item
                    })
                })
                initialData.current = basket_list
                setAllocationData(basket_list)
            }
        })
    }
    return (
        <div className="row">
            {
                showDetail ?
                    <div className="col-12">

                        <span className="e-breadcrumb-link"><span onClick={() => navigate(-1)} className="cursor-pointer">Orders</span>/Create cart order</span>
                        <div className="card e-dashboard-card border-0 mt-2 h-auto p-0">
                            <div className="row p-3 ps-3">
                                <div className="col-lg-5 col-6 pe-0">
                                    <div className="d-flex gap-4 align-items-center">
                                        <div className="e-profile-icon">{detail.short_name}</div>
                                        <span className="e-profile-name">{detail.name}</span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="e-info p-4">
                                        <div className="row">
                                            <div className="col-4">
                                                <h6>Cust.ID</h6>
                                                <p>{detail.mira_customer_id}</p>
                                            </div>
                                            <div className="col-4">
                                                <h6>Joined on</h6>
                                                <p>{detail.joined_on}</p>
                                            </div>
                                            <div className="col-4">
                                                <h6>Phone</h6>
                                                <p>{detail.mobile}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card e-dashboard-card border-0 mt-3 h-auto">
                            <div className="d-flex justify-content-between">
                                <h6 className="e-section-title mb-0">
                                    Add basket & allocations
                                </h6>
                                <img src={require("../../assets/images/navigation/back.svg").default}
                                    alt="back"
                                    className="cursor-pointer"
                                    onClick={_handleBack} />
                            </div>
                            <div className="e-create-cart mt-3 p-3">

                                <div className="row align-items-center">
                                    <div className="col-3">
                                        <h6 className="e-font-14 mb-1">Investment amount </h6>
                                        <AmountInput defaultValue={amount}
                                            // wrapperClass={`${sipChecked === false ? "opacity-0" : ""}`}
                                            valueChange={(input_value) => {
                                                _handleAmount(input_value)
                                            }} />
                                    </div>
                                    {(selectedBaskets.length > 0 || addedFunds.length > 0) && <div className="col-2 pt-3">
                                        <TextBtn name="Clear All"
                                            handleClick={_clearAll} />
                                    </div>}
                                    <div className="col-12 ">
                                        <h6 className="e-font-14 mb-1 mt-3">Invest  type </h6>
                                        <div className="d-flex align-items-center gap-md-4 gap-2">
                                            <div className="d-flex align-items-center gap-2 cursor-pointer" onClick={() => {
                                                _handleChecked(false)

                                            }}>
                                                <Input type="radio"
                                                    id="e-select"
                                                    className="cursor-pointer"
                                                    checked={sipChecked === false}
                                                    valueChange={() => {
                                                        _handleChecked(false)
                                                    }} />
                                                <span className="e-font-14 color-grey e-poppins-regular">Lumpsum</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2 cursor-pointer " onClick={() => {
                                                _handleChecked(true)
                                            }}>
                                                <Input type="radio"
                                                    id="e-select"
                                                    className="cursor-pointer"
                                                    checked={sipChecked === true}
                                                    valueChange={() => {
                                                        _handleChecked(true)
                                                    }} />
                                                <span className="e-font-14 color-grey e-poppins-regular">SIP</span>
                                            </div>


                                        </div>
                                    </div>
                                    {sipChecked === true && <div className="col-4 pt-2">
                                        <h6 className="e-font-14 mb-1">SIP date</h6>
                                        <div className={!sipChecked ? "e-sip-date e-sip-date-disabled  " : "e-sip-date "} onClick={() => _handleDateModal(true)}>
                                            {_formatSip(selectedDate)} of every month
                                            <img src={require("../../assets/images/modal/calendar.svg").default}
                                                alt="calendar"
                                                className="ms-2" />
                                        </div>
                                    </div>}
                                </div>
                                <div className="e-cart-basket-inputs mt-3">
                                    <div className="row gx-2">
                                        <div className="col-xxl-3 col-xl-4 col-5">
                                            <h6 className="e-section-title">Baskets</h6>
                                        </div>
                                        <div className="col-xxl-1 col-xl-2 col-3">
                                            <h6 className="e-section-title">Allocation</h6>
                                        </div>
                                        <div className="col-4 ps-4">
                                            Amount
                                        </div>
                                    </div>
                                    {
                                        allocationData.map((item, index) => {
                                            return (
                                                <div className="row gx-2 align-items-center mb-2">
                                                    <div className="col-xxl-3 col-xl-4 col-5 mb-sm-0 mb-3 pt-1">
                                                        <div className="d-flex gap-3 align-items-center">
                                                            <Input type="checkbox"
                                                                id="e-select"
                                                                className="cursor-pointer"
                                                                checked={selectedBaskets.some((data) => data.id === item.id)}
                                                                valueChange={() => _handleSelect(item, index)} />
                                                            <h6 className="mb-0">{item.basket}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-1 col-xl-2 col-3">
                                                        <Input type="number"
                                                            suffix="%"
                                                            value={item.allocation == 0.00 ? 0 : item.allocation}
                                                            is_zero
                                                            two_decimal
                                                            wrapperClass="position-relative w-100"
                                                            disabled={!selectedBaskets.some((data) => data.id === item.id)}
                                                            valueChange={(input_value) => _handleAllocation(input_value, item.id, index)} />
                                                    </div>
                                                    <div className="col-4 ps-4">
                                                        <AmountInput placeholder={sipChecked ? `Min: ${item.min_sip_amount.toLocaleString('en-IN')}` : `Min: ${item.min_lumpsum_amount.toLocaleString('en-IN')}`}
                                                            disabled={!selectedBaskets.some((data) => data.id === item.id)}
                                                            defaultValue={item.amount}
                                                            className="w-120px"
                                                            is_zero
                                                            valueChange={(input_value) => _handleBasketAmount(input_value, item.id, index, item.min_lumpsum_amount)} />
                                                        {/* <span className="e-min"> Min: ₹{item.min_lumpsum_amount.toLocaleString('en-IN')}</span> */}

                                                        {/* <div className={`position-relative`}>
                                                                <h6 className="mb-0">{!sipChecked ? "" : item.amount}</h6>
                                                                <AmountInput disabled
                                                                    placeholder={`Min: ${item.min_sip_amount.toLocaleString('en-IN')}`}
                                                                    // disabled={!selectedBaskets.some((data) => data.id === item.id) || sipChecked === false}
                                                                    defaultValue={sipChecked ? item.amount : ""}
                                                                    className="w-120px"
                                                                    is_zero
                                                                    valueChange={(input_value) => _handleSip(input_value, item.id, index, item.min_sip_amount)} />
                                                                <span className="e-min"> Min: ₹{item.min_sip_amount.toLocaleString('en-IN')}</span>
                                                            </div> */}
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <h6 className="e-section-title">Custom funds</h6>
                                        </div>

                                    </div>
                                    {
                                        addedFunds.map((item, index) => {
                                            return (
                                                <div className="row gx-2 mb-2 align-items-center">
                                                    <div className='col-xxl-3 col-xl-4 col-5 mb-sm-0 mb-3 position-relative' >
                                                        <Input type='text'
                                                            placeholder="Search for fund"
                                                            className='mt-0'
                                                            value={item.scheme_name}
                                                            valueChange={(value) => {
                                                                _handleFundSelect(value, index, item.scheme_code, item);
                                                                if (value.length > 0) {
                                                                    _getFunds(value, index)
                                                                } else {
                                                                    setShowDropdown(null);
                                                                }
                                                            }} />
                                                        {
                                                            showDropdown === index ?
                                                                <div className='position-absolute e-cart-search-dropdown' ref={searchRef} style={{ top: "50px" }}>
                                                                    {fundsList.map((fund, key) => {
                                                                        return (
                                                                            <p key={key} onClick={() => {
                                                                                _getFundDetail(fund, index)
                                                                            }}>{fund.scheme_name}</p>
                                                                        )
                                                                    })}
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                    <div className="col-xxl-1 col-xl-2 col-3">
                                                        <Input type="number"
                                                            suffix="%"
                                                            two_decimal
                                                            value={item.allocation == 0.00 ? 0 : item.allocation}
                                                            is_zero
                                                            wrapperClass="position-relative w-100"
                                                            disabled={item.scheme_code === "" ? true : false}
                                                            valueChange={(input_value) => _handleFundAllocation(input_value, index)} />
                                                    </div>
                                                    <div className="col-4 ps-4">
                                                        <div className="d-flex align-items-start gap-4">
                                                            <div className={`position-relative`}>
                                                                <AmountInput
                                                                    placeholder={sipChecked ? item.min_sip_investment ? `Min: ${item.min_sip_investment.toLocaleString('en-IN')}` : "Select fund" : item.min_lumpsum_investment ? `Min: ${item.min_lumpsum_investment.toLocaleString('en-IN')}` : "Select fund"}
                                                                    defaultValue={item.amount}
                                                                    is_zero
                                                                    disabled={item.scheme_code === "" ? true : false}
                                                                    className="w-120px"
                                                                    valueChange={(input_value) => _handleFundAmount(input_value, index)} />
                                                                {/* {item.min_lumpsum_investment && <span className="e-min"> Min: ₹{item.min_lumpsum_investment.toLocaleString('en-IN')}</span>} */}
                                                            </div>
                                                            {/* <div className={`position-relative pt-2 ${sipChecked === false ? "d-none" : ""}`}>
                                                                <AmountInput
                                                                    defaultValue={item.amount}
                                                                    is_zero
                                                                    className="w-120px"
                                                                    disabled
                                                                    placeholder={item.min_sip_investment ? `Min: ${item.min_sip_investment.toLocaleString('en-IN')}` : "Select fund"}
                                                                    valueChange={(input_value) => _handleFundSip(input_value, index)} />
                                                                {item.min_sip_investment && <span className="e-min"> Min: ₹{item.min_sip_investment.toLocaleString('en-IN')}</span>}
                                                            </div> */}
                                                            <img src={require("../../assets/images/manage-content/Delete.svg").default}
                                                                alt="delete"
                                                                onClick={() => _handleDelete(index)}
                                                                className="mt-1 cursor-pointer" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <h6 className="e-add-new mb-0 cursor-pointer" onClick={_handleAdd}>
                                                <img src={AddNew} alt="add" className="pe-1" />
                                                Add New
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-3 ps-3">
                                    <div className="col-xxl-3 col-xl-4 col-5">
                                        <h6 className="e-section-title">Total allocation</h6>
                                    </div>
                                    <div className="col-xxl-1 col-xl-2 col-3">
                                        <h6 className="e-section-title">{(_getTotalAllocation()).toFixed(2)}%</h6>
                                    </div>
                                    <div className="col-4 ps-4">
                                        <h6 className="e-section-title ps-2">₹{_getTotalAmount(1)}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-3 pt-4 mt-3">
                                <div className="col-3">
                                    <PrimaryBtn name="Send to User"
                                        className={selectedBaskets.length === 0 && addedFunds.length === 0 ? "e-disabled" : btnLoader === true ? "e-disabled e-btn-loader" : ""}
                                        handleClick={_handleSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-lg-12  h-100vh">
                        <div className="card e-dashboard-card border-0 py-1">
                            <div className="col-lg-12 pt-2">
                                <h6 className="e-section-title mb-0">
                                    Create cart order
                                </h6>
                            </div>
                            <div className='col-lg-3 position-relative pt-4' >
                                <div onClick={() => _getCustomerFilterData(search)}>
                                    <label className="mb-0">Search & select user</label>
                                    <Input type='text'
                                        placeholder="Search for customer"
                                        className='py-2 e-cart-input mt-0'
                                        value={selectedCustomer !== null ? selectedCustomer.label : search}
                                        valueChange={(value) => {
                                            setSearch(value);
                                            _getCustomerFilterData(value)
                                        }} />
                                </div>
                                {
                                    showDropdown ?
                                        <div className='position-absolute e-cart-search-dropdown' ref={searchRef}>
                                            {customerList.map((item, index) => {
                                                return (
                                                    <p key={index} onClick={() => {
                                                        _selectCustomer(item)
                                                    }}>{item.label}</p>
                                                )
                                            })}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </div>
            }

            <ToastContainer position="bottom-right" autoClose={3000} />
            {
                (showDateModal === true) &&
                <Fragment>
                    <SIPDateModal apiDates={[]}
                        selectedSIPDate={selectedDate}
                        handleSelect={_handleSelectDate}
                        handleCloseDateModal={() => _handleDateModal(false)} />
                </Fragment>
            }
            {
                (orderSent === true) &&
                <Fragment>
                    <SuccessConfirmationModal closeModal={_handleClose}
                        handleNavigate={_viewOrders} />
                </Fragment>
            }
        </div >
    )
}

export default CreateCartOrder