/*
 *   File :  success-confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  Success confirmation order
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';
import { SecondaryBtn } from '../cta';

/*import components*/

export default function SuccessConfirmationModal(props) {
    return (
        <div className={`${styles.e_add_fund} modal fade`}
            id={"confirmSuccess"}
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="success"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <img src={require("../../assets/images/modal/successs.png").default}
                        alt="success"
                        width={62}
                        height={62}
                        className='mx-auto mt-1' />
                    <h6 className='pt-4 mt-3 mb-4 pb-2 text-center'>Successfully sent to user</h6>
                    <div className="row pb-1">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-center gap-3">
                                <SecondaryBtn
                                    name="View orders"
                                    className='e-modal-submit'
                                    cancel="modal"
                                    handleClick={props.handleNavigate} />
                                <SecondaryBtn
                                    name="Close"
                                    className={`${styles.e_cancel} e-cancel`}
                                    cancel="modal"
                                    handleClick={props.closeModal} />
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </div>

    )
}
