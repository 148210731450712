/*import package*/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*import containers*/
// import Contents from './containers/contents.js';
import Login from './containers/auth/login.js';
import Dashboard from './containers/dashboard/index';
import Users from './containers/users/index';
import UserDetails from './containers/users/detail.js';
import ForgotPassword from './containers/auth/forgotPassword.js';
import VerifyOTP from './containers/auth/verifyOTP.js';
import CreatePassword from './containers/auth/createPassword.js';
import Notification from './containers/notification/index';
import RiskFunds from './containers/Risk and Funds';
import ManageAllocation from './containers/allocation'
import ManageReturns from './containers/returns'
import ManageContent from './containers/manage-content'
import ValuePairs from './containers/pairs'
import ManageBanner from './containers/banner'
import ManageCampaign from './containers/campaign';
import CampaignDetails from './containers/campaign/detail.js';
import AllNotification from './containers/allnotification/index';
import Orders from './containers/orders/index';
import ManageMaturity from './containers/target-maturity/index';
import VersionHistory from './containers/version-history';
import RebalanceHistory from './containers/rebalance-history';
import OrderDetail from './containers/orders/order-detail.js';

/*import component*/
import { Footer, InnerHeader, Sidebar } from './components/navigation/index';
import PendingOrders from './containers/orders/pending-orders.js';
import MIRAXray from './containers/xray/index.js';
import CreateCartOrder from './containers/orders/create-cart-order.js';

export default function App(props) {
    const [collapseMenu, setCollapseMenu] = useState(false);
    const [width, setWidth] = useState(window.screen.width)
    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setWidth(event.target.innerWidth)
            if (event.target.innerWidth <= 1024) {
                setCollapseMenu(true)
            } else {
                setCollapseMenu(false)
            }
        })
    }, [])
    return (
        <>
            {
                localStorage.getItem("access_token") ?
                    <Router>
                        <div className="container-fluid d-flex flex-column flex-lg-row p-0">
                            <div className={collapseMenu || width <= 1024 ? "e-sidebar-collapsed sticky-top p-0 " : "e-sidebar-wrap sticky-top p-0"}>
                                <Sidebar is_menu_collapse={collapseMenu} width={width} />
                            </div>
                            <div className="e-content-wrap position-relative p-0">
                                <InnerHeader collapse={() => { setCollapseMenu(!collapseMenu) }} />
                                <div className="p-lg-2 ps-5 mx-lg-0 mx-3 pt-2">
                                    <Routes>
                                        <Route exact path="/" element={<Dashboard />} />
                                        {/* <Route path="/home" element={<Contents />} /> */}
                                        <Route path="/users"
                                            element={
                                                <Users />
                                            }
                                        />
                                        <Route path="/user-detail"
                                            element={
                                                <UserDetails />
                                            }
                                        />
                                        <Route path="/notifications"
                                            element={
                                                <Notification />
                                            }
                                        />
                                        <Route path="/funds"
                                            element={
                                                <RiskFunds />
                                            }
                                        />
                                        <Route path="/allocation"
                                            element={
                                                <ManageAllocation />
                                            }
                                        />
                                        <Route path="/target-maturity"
                                            element={
                                                <ManageMaturity />
                                            }
                                        />
                                        <Route path="/returns"
                                            element={
                                                <ManageReturns />
                                            }
                                        />
                                        <Route path="/content"
                                            element={
                                                <ManageContent />
                                            }
                                        />
                                        <Route path="/value-pairs"
                                            element={
                                                <ValuePairs />
                                            }
                                        />
                                        <Route path="/banner"
                                            element={
                                                <ManageBanner />
                                            }
                                        />
                                        <Route path="/campaign"
                                            element={
                                                <ManageCampaign />
                                            }
                                        />
                                        <Route path="/campaign-detail"
                                            element={
                                                <CampaignDetails />
                                            }
                                        />
                                        <Route path="/orders"
                                            element={
                                                <Orders />
                                            }
                                        />

                                        <Route path="/orders/create-cart-order"
                                            element={
                                                <CreateCartOrder />
                                            }
                                        />

                                        <Route path="/order-detail"
                                            element={
                                                <OrderDetail />
                                            }
                                        />
                                        <Route path="/pending-orders"
                                            element={
                                                <PendingOrders />
                                            }
                                        />
                                        <Route path="/mira-xray"
                                            element={
                                                <MIRAXray />
                                            }
                                        />
                                        <Route path="/all-notification"
                                            element={
                                                <AllNotification />
                                            }
                                        />
                                        <Route path="/version-history"
                                            element={
                                                <VersionHistory />
                                            }
                                        />
                                        <Route path="/rebalance-history"
                                            element={
                                                <RebalanceHistory />
                                            }
                                        />
                                    </Routes>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </Router>
                    :
                    <Router>
                        <div>
                            <Routes>
                                <Route exact path="/"
                                    element={
                                        <Login />
                                    }
                                />
                                <Route exact path="/forgot-password"
                                    element={
                                        <ForgotPassword />
                                    }
                                />
                                <Route exact path="/verify-otp"
                                    element={
                                        <VerifyOTP />
                                    }
                                />
                                <Route exact path="/create-password"
                                    element={
                                        <CreatePassword />
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
            }
        </>
    )

}